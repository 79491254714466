@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css');
@font-face {
  font-family: 'Barlow Condensed';
  src: url('./fonts/BarlowCondensed-Regular.ttf');
}

@font-face {
  font-family: 'Barlow Condensed Bold Italic';
  src: url('./fonts/BarlowCondensed-BoldItalic.ttf');
}

.App {
  text-align: center;

  .video {
    min-width: 300px;
    min-height: 300px;
  }
}

.Header {
  align-items: center;
  background-color: #181818;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  &_Logo_Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    width: 100%;

    .Logo {
      display: flex;
      justify-content: center;
      padding: 20px;
      img {
        width: 100%;
      }
    }
  }

  &_Search_Container {
    background: #181818;
    padding: 26px;
  }

  &_Search.input-group {
    width: auto;
    outline: none !important;

    .form-control {
      background-color: #6f6f6f !important;
      color: white;
      outline: none !important;
      box-shadow: none;
      border-radius: 0;
      border: none;
      outline: none;
    }

    .form-control:focus {
      outline: none !important;
    }

    .input-group-prepend {
      .input-group-text {
        color: white;
        background-color: #6f6f6f !important;
        border: none;
        border-radius: 0;
        height: 40px;
      }
    }
  }
}

.VideoList {
  background-color: #181818;
  color: white;
  font-family: 'Barlow Condensed';
  height: 100%;
  padding: 26px 26px 100px 26px;

  h1 {
    font-family: 'Barlow Condensed Bold Italic';
    font-weight: 800;
    font-size: 48px;
    font-style: italic;
    line-height: 1em;
  }

  &_List {
    display: flex;
    flex-wrap: wrap;
    min-height: calc(100vh - 315px);
    height: 100%;
    justify-content: space-around;
    list-style: none;
    padding: 0;

    &_Item {
      border: none;
      margin-top: 10px;
      text-decoration: none;
      width: 100%;

      .card-body {
        background-color: #181818;
        border: none;
        color: white;
        font-size: 1.5rem;
        font-weight: 800;
        font-size: 24px;
        text-align: left;
        padding: 10px 0;
        margin-bottom: 40px;
      }

      &_Image_Button {
        background: none;
        border: none;
        border-radius: 0px;
        color: transparent;
        margin: 0;
        padding: 0;
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }
}

.Footer {
  width: 100%;
  background-color: #454545;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;

  .btn.btn-primary {
    outline: none;
    border-radius: 0;
    font-size: 24px;
    line-height: 24px;
    background-color: transparent;
    border: none;
    width: 213px;
    background-image: url('./voteButton.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .vote-button.btn.btn-primary:focus,
  .vote-button.btn.btn-primary:hover {
    outline: none !important;
  }

  a,
  a:focus,
  a:hover {
    outline: none !important;
  }

  &_Right {
    display: flex;
    justify-content: center;
    color: black;
    align-items: center;

    a,
    a:visited,
    a:hover {
      color: black;
      img {
        width: 25px;
      }
    }

    i {
      font-size: 18px;
      padding: 3px;
    }

    &_Image a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 3px;
      height: 27px;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-container {
  font-family: 'Barlow Condensed';
  max-width: 90%;
  margin: auto;

  .vote-button.btn.btn-primary {
    font-size: 24px;
    line-height: 24px;
    background-color: transparent;
    border: none;
    width: 213px;
    background-image: url('./voteButton.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .modal-content {
    border: none;
    background-color: transparent;
    max-height: 80vh;

    .modal-header {
      padding: 0;
      background-color: transparent;

      .close {
        padding: 0;
        color: white;
        font-size: 48px;
      }

      .modal-title {
        font-family: 'Barlow Condensed Bold Italic';
        font-size: 24px;
        text-align: left;
        color: white;
      }
    }
    .modal-body {
      background-color: white;
      max-height: 90vh;

      .modal-bio {
        max-height: 120px;
        overflow-y: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
      .modal-bio::-webkit-scrollbar {
        display: none;
      }
    }
    .modal-footer {
      border-bottom: none !important;
      border-top: none !important;
      background-color: transparent;
      display: flex;
      justify-content: center;
    }
  }
}
@media only screen and (min-width: 539px) {
  .Header {
    &_Logo_Container {
      .Logo {
        img {
          width: 100%;
        }
      }
    }
  }

  .VideoList {
    flex-wrap: nowrap;
    justify-content: space-around;
  }

  .VideoList_List_Item {
    max-width: 23%;
  }
  .modal-container {
    margin: 5% auto;

    .modal-content {
      max-height: none;
    }
  }

  .modal-body {
    iframe {
      height: 315px;
    }
  }
  .Footer {
    .container {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@media only screen and (min-width: 767px) {
  .Header {
    &_Logo_Container {
      .Logo {
        img {
          width: 100%;
        }
      }
    }
  }

  .VideoList {
    flex-wrap: nowrap;
    justify-content: space-around;
  }

  .VideoList_List_Item {
    margin: 10px;
    max-width: 23%
  }

  .Footer {
    width: 100%;
    height: 80px;
    padding-right: 10px;

    i {
      font-size: 32px;
      padding: 10px;
    }
    &_Right {
      &_Image a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;

        img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
  .modal-container {
    margin: auto;
    margin-top: 11%;
    .modal-content {
      max-height: none;
    }
    .modal-header {
      .close {
        color: white;
        font-size: 48px;
        position: relative;
        right: -5%;
        height: 32px;
      }
    }
  }

  .modal-body {
    iframe {
      height: 415px;
    }
  }
}

@media only screen and (min-width: 1023px) {
  .modal-header {
    .close {
      font-size: 28px;
    }

    .modal-title {
      font-size: 28px;
    }
  }
  .modal-container {
    width: 60%;
    margin: auto;
    margin-top: 2%;

    .modal-footer .btn {
      font-size: 18px;
    }
  }
  .modal-bio {
    font-size: 18px;
    height: 180px;
  }
  .modal-body {
    iframe {
      height: 415px;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .modal-header {
    .close {
      font-size: 34px;
    }

    .modal-title {
      font-size: 24px;
    }
  }
  .modal-container {
    width: 30%;
    margin: auto;
    margin-top: 3%;
  }
  .modal-bio {
    font-size: 18px;
  }
  .modal-body {
    iframe {
      height: 315px;
    }
  }
}
